<template>
  <BOverlay :show="isFetching">
    <template v-if="data.id">
      <template v-if="!openedChecklist">
        <BRow>
          <BCol
              cols="12"
              md="6"
          >
            <RequestInfoCard
              :item="data"
              @needToUpdate="needToUpdate"
              @updateField="updateField"
              @deleteEntity="deleteEntity"
            />
            <RequestCheckListsCard
              :item="data"
              :is-request-status-completed="isRequestStatusCompleted"
              @needToUpdate="needToUpdate"
              @updateField="updateField"
              @deleteEntity="deleteEntity"
            />
            <BCard>
                <h4 class="m-0 mb-2">
                    Файлы
                </h4>
                <BTabs
                    lazy
                    fill
                >
                    <BTab>
                        <template #title>
                            Заявка
                        </template>
                        <RequestFileUploadCard
                            is-editable
                            :items="requestFiles"
                            :payload="requestFilesPagination"
                            :file-types="fileTypes"
                            @get="getRequestFiles"
                            @submit="addFiles"
                            @delete="handleDeleteFile"
                        />
                    </BTab>
                    <BTab>
                        <template #title>
                            Объект
                        </template>
                        <RequestFileUploadCard
                            :items="facilityFiles"
                            :file-types="fileTypes"
                            :payload="facilityFilesPagination"
                            @get="getFacilityFiles"
                        />
                    </BTab>
                </BTabs>
            </BCard>
          </BCol>
          <BCol
              cols="12"
              md="6"
          >
            <RequestPropsCard
              :item="data"
              :is-request-status-completed="isRequestStatusCompleted"
              @needToUpdate="needToUpdate"
              @updateField="updateField"
              @updateFields="updateFields"
            />

            <RequestEquipmentCard
              :item="data"
              @needToUpdate="needToUpdate"
              @updateField="updateField"
              @updateFields="updateFields"
            />
          </BCol>
        </BRow>
        <BRow>
          <BCol
              cols="12"
              md="12"
          >
            <RequestWorkCard
              :item="data"
              :is-request-status-completed="isRequestStatusCompleted"
              @needToUpdate="needToUpdate"
              @updateField="updateField"
              @updateFields="updateFields"
              @openChecklist="onOpenChecklist"
            />
          </BCol>
        </BRow>
      </template>
      <template v-else>
        <div
          class="mb-2 cursor-pointer"
          @click="closeChecklist"
        >
          <feather-icon icon="ChevronLeftIcon" /> Назад
        </div>
        <BRow>
          <BCol
              cols="12"
              md="6"
          >
            <RequestInfoCardWork
              :item="data"
              @updateStatus="updateStatus"
            />
          </BCol>
        </BRow>
        <BRow>
          <BCol
              cols="12"
              md="6"
          >
            <div
              v-for="checklist in data.checklists"
              :key="checklist.id"
            >
              <WorkCheckListFull
                  id="workCheckListFulld"
                  :request="data"
                  :item="checklist"
                  class="mt-2"
                  @needToUpdate="needToUpdate"
                  @closeChecklist="closeChecklist"
              />
            </div>
            <BCard v-if="showSignCard">
              <h4>Подпись клиента</h4>
              <Sign
                  class="mt-2"
                  :value="signModel"
                  :price="data.price"
                  @signed="onSigned"
              />
              <div class="mt-2">
                <BButton
                    :disabled="!signTriggered"
                    variant="primary"
                    @click="signSubmit"
                >
                  Завершить
                </BButton>
              </div>
            </BCard>
          </BCol>
        </BRow>
      </template>
    </template>
  </BOverlay>
</template>

<script>
import {
  ref, toRefs, provide, computed, watch,
} from '@vue/composition-api';
import { BOverlay, BCard, BRow, BCol, BButton, BTabs, BTab } from 'bootstrap-vue';
import { useRouter } from '@core/utils/utils';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import { useI18n } from '@/hooks/useI18n';
import useGetParams from '@/hooks/useGetParams';
import Sign from "@/components/Sign.vue";
import {axiosIns} from "@/store/api";

export default {
  components: {
    Sign,
    BOverlay,
    BRow,
    BCard,
    BButton,
    BCol,
    BTabs,
    BTab,
    RequestInfoCard: () => import('@/components/requests/InfoCard.vue'),
    RequestPropsCard: () => import('@/components/requests/PropsCard.vue'),
    RequestEquipmentCard: () => import('@/components/requests/EquipmentCard.vue'),
    RequestCheckListsCard: () => import('@/components/requests/CheckListsCard.vue'),
    RequestWorkCard: () => import('@/components/requests/WorkCard.vue'),
    RequestInfoCardWork: () => import('@/components/requests/InfoCardWork.vue'),
    WorkCheckListFull: () => import('@/components/requests/WorkChecklistFull.vue'),
    RequestFileUploadCard: () => import('@/components/requests/FileUploadCard.vue'),
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    const { id } = toRefs(props);
    const store = useStore();
    const { successToast, dangerToast } = useToast();
    const { t } = useI18n();
    const { router } = useRouter();
    const signModel = ref(null);
    const signTriggered = ref(false);
    const signDisplay = ref(false);
    const requestFiles = ref({});
    const requestFilesPagination = ref({
        page: 1,
        perPage: 5,
        type: null,
    });
    const facilityFiles = ref({});
    const facilityFilesPagination = ref({
        page: 1,
        perPage: 5,
        type: null,
    });

    const fileTypes = ref(null);
      const getDictionaries = async () => {
          const result = await store.dispatch('dictionaries/getDictionaries', {
              dictionaries: 'file_types'
          });

          if (result) {
              fileTypes.value = store.state.dictionaries.list.file_types
                  .map((el) => ({text: el.name, value: el.id, disabled: false}));
          }
      };

      getDictionaries();

    provide('mapMarkerIcon', 'object');

    const { findGetParam, updateGetParam } = useGetParams();
    const data = ref({});

    const scrollToWork = () => {
      window.scrollTo(0, 0);
    };
    const openedChecklist = computed({
      get() {
        const id = findGetParam('openedChecklist');
        return data.value.checklists?.find((i) => i.id === +id) || undefined;
      },
      set(value) {
        updateGetParam('openedChecklist', value?.id || undefined);
      },
    });

    const showSignCard = computed(() => !data.value.checklists?.find((el) => el.finished === null)
          && data.value.client_sign_required);

    const closeChecklist = () => {
      openedChecklist.value = null;
      scrollToWork();
    };
    const onOpenChecklist = (checklist) => {
      openedChecklist.value = checklist;
      scrollToWork();
    };

    const getRequestFiles = (payload) => {
        store.dispatch('requests/getFiles', { id: Number(id.value), payload }).then((res) => {
            requestFiles.value = res.data;
            requestFilesPagination.value = {
                page: res.data.current_page,
                perPage: res.data.per_page,
                total: res.data.total
            }
        });
    }

    const getFacilityFiles = (payload) => {
        store.dispatch('requests/getFacilityFiles', { id: Number(id.value), payload }).then((res) => {
            facilityFiles.value = res.data;
            facilityFilesPagination.value = {
                page: res.data.current_page,
                perPage: res.data.per_page,
                total: res.data.total
            }
        });
    }

    const isFetching = ref(false);
    const getData = async (silent) => {
      if (isFetching.value) return;
      if (!silent) isFetching.value = true;

      await getRequestFiles(requestFilesPagination.value);
      await getFacilityFiles(facilityFilesPagination.value);

      const response = await store.dispatch('requests/findOne', id.value);
      isFetching.value = false;
      if (response.result) {
        data.value = response.data;
        router.currentRoute.meta.breadcrumb.at(1).entryId = `${response.data.number}`;
        store.commit('app/UPDATE_REFRESH_META');
        if (openedChecklist.value) {
          // eslint-disable-next-line max-len
          openedChecklist.value = data.value.checklists?.find((i) => i.id === openedChecklist.value.id) || {};
        }
      }
    };
    getData();

    const needToUpdate = async (silent = true) => {
      await getData(silent);
      if (!silent) {
        successToast(t('settings.messageUpdated.title'), t('settings.messageUpdated.text'));
      }
    };
    provide('needToUpdate', needToUpdate);

    const updateField = async ({
      field, value, update = false, swap = true,
    }) => {
      const model = {};
      model[field] = value;
      const { result } = await store.dispatch('requests/update', {
        id: data.value.id,
        model,
      });

      if (result) {
        successToast(t('settings.messageUpdated.title'), t('settings.messageUpdated.text'));
        if (swap) data.value[field] = value;
        if (update) needToUpdate(true);
      } else {
        dangerToast(t('errors.something-went-wrong.text'), t('errors.message.updateFailedMessage'));
      }
    };

    const updateFields = async ({ model, silent = false, update = true }) => {
      const { result } = await store.dispatch('requests/update', {
        id: data.value.id,
        model,
      });

      if (result) {
        if (!silent) {
          successToast(t('settings.messageUpdated.title'), t('settings.messageUpdated.text'));
        }
        if (update) needToUpdate(true);
      } else {
        dangerToast(t('errors.something-went-wrong.text'), t('errors.message.updateFailedMessage'));
      }
    };

    const deleteEntity = async () => {
      const { result } = await store.dispatch('requests/delete', {
        id: id.value,
      });
      if (result) {
        router.replace('/requests');
      }
    };

    const statuses = ref(null);
    const getStatuses = async () => {
      const response = (await store.dispatch('requests/statuses'))?.data;
      statuses.value = response;
      return response;
    };
    getStatuses();
    // eslint-disable-next-line max-len
    const isRequestStatusCompleted = ref(false);
    // eslint-disable-next-line consistent-return
    const getRequestStatusCompleted = () => {
      if (!statuses.value) {
        return false;
      }
      // eslint-disable-next-line max-len
      isRequestStatusCompleted.value = statuses.value[statuses.value.length - 2].id === data.value.status_id;
    };

    const updateStatus = (value) => {
      data.value.status = value;
    };

    const signSubmit = async () => {
      const { result } = await store.dispatch('requests/sign', { requestId: data.value.id, sign: signModel.value });
      if (result) {
        const { result: finishResult, data: finishData } = await store.dispatch('requests/finish', { requestId: data.value.id, });
        if (finishResult) {
          data.value.status = finishData.status;
          data.value.status_id = finishData.status_id;
        }
      }
      getData();
      closeChecklist();
    };

    const onSigned = async (sign) => {
      signModel.value = sign;
      signTriggered.value = true;
    };

    const addFiles = async (filesArray) => {
      const formData = new FormData();

      filesArray.forEach((file) => {
          formData.append('files[]', file);
      });

      await axiosIns.post('tools/upload-files', formData)
        .then((res) => {
            if (res.status === 422) {
                dangerToast(t('filesUpload.uploadSuccessTitle'), res.data.message ?? t("filesUpload.uploadErrorText"))
            } else {
                const { files } = res.data;

                store.dispatch('requests/addFiles', { id: Number(id.value), files: files.map((file) => file.id) }).then(() => {
                    successToast(t('filesUpload.uploadSuccessTitle'), t('filesUpload.uploadSuccessText'));
                }).then(() => {
                    store.dispatch('requests/getFiles', { id: Number(id.value), payload: requestFilesPagination.value }).then((res) => {
                        requestFiles.value = res.data;
                        requestFilesPagination.value = {
                            page: res.data.current_page,
                            perPage: res.data.per_page,
                            total: res.data.total
                        }
                    });
                }).catch(() => {
                    dangerToast(t('file_upload.message.error'));
                });
            }
        })
        .catch(() => {
            dangerToast(t('file_upload.message.error'));
        });
    }

    const handleDeleteFile = (fileId) => {
        if (fileId) {
            store.dispatch('requests/deleteFile', { requestId: Number(id.value), fileId, payload: requestFilesPagination.value }).then((res) => {
                if (!res.data.data.length && res.data.current_page > 1) {
                    store.dispatch('requests/getFiles', { id: Number(id.value), payload: { ...requestFilesPagination.value, page: requestFilesPagination.value - 1 } }).then((res) => {
                        requestFiles.value = res.data;
                        requestFilesPagination.value = {
                            page: res.data.current_page,
                            perPage: res.data.per_page,
                            total: res.data.total
                        }
                    });
                } else {
                    requestFiles.value = res.data;
                    requestFilesPagination.value = {
                        page: res.data.current_page,
                        perPage: res.data.per_page,
                        total: res.data.total
                    }
                }
                successToast(t('filesUpload.fileSuccessDeleteTitle'), t('filesUpload.fileSuccessDeleteText'))
            })
        }
    }

    watch(data, (newData) => {
      if (newData?.status_id) {
        getRequestStatusCompleted();
      }
    });

    watch(statuses, (payload) => {
      if (payload?.length) {
        getRequestStatusCompleted();
      }
    });

    return {
      data,
      isFetching,
      needToUpdate,
      updateField,
      deleteEntity,
      updateFields,

      getRequestFiles,
      getFacilityFiles,

      openedChecklist,
      closeChecklist,
      onOpenChecklist,
      isRequestStatusCompleted,
      updateStatus,

      signModel,
      signTriggered,
      signDisplay,
      signSubmit,
      onSigned,
      showSignCard,
      addFiles,
      handleDeleteFile,
      requestFiles,
      requestFilesPagination,
      facilityFiles,
      facilityFilesPagination,
        fileTypes,
    };
  },
};
</script>
