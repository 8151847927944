<template>
  <div>
    <div
      v-b-modal.sign-modal
      class="dropdown-area"
    >
      <template v-if="!value">
        Добавить подпись
      </template>
      <template v-else>
        <img
          :src="value"
          style="max-width: 100%; max-height: 100%"
        >
      </template>
    </div>

    <b-modal
      id="sign-modal"
      ref="signModal"
      title="Поставьте подпись"
      size="lg"
      @shown="createPad"
    >
      <canvas
        ref="canvas"
        class="canvas"
        width="760.8"
        height="512"
      />

      <template #modal-footer="{}">
        <div
          class="d-flex w-100 align-items-center justify-content-between"
        >
          <Base
              v-if="price"
              :value="price"
              entity="request"
              alias="requests"
              field="price"
              icon="DollarSignIcon"
              single-line
              money
              readonly
          />
          <div
            class="d-flex align-items-center gap-2"
          >
            <BButton @click="$bvModal.hide('sign-modal')">
              Отменить
            </BButton>
            <BButton
                variant="primary"
                :disabled="!triggered"
                @click="submit"
            >
              Сохранить
            </BButton>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import SignaturePad from 'signature_pad';
import { BButton } from 'bootstrap-vue';
import Base from '@/components/fields/base/index.vue';

export default {
  components: {
    BButton,
    Base,
  },
  props: {
    value: String,
    price: {
      type: Number,
      required: false
    }
  },
  setup(props, { emit }) {
    const canvas = ref(null);
    const triggered = ref(false);
    const signaturePad = ref(null);
    const resizeCanvas = () => {
      // When zoomed out to less than 100%, for some very strange reason,
      // some browsers report devicePixelRatio as less than 1
      // and only part of the canvas is cleared then.
      const ratio = Math.max(window.devicePixelRatio || 1, 1);

      // This part causes the canvas to be cleared
      canvas.value.width = canvas.value.offsetWidth * ratio;
      canvas.value.height = canvas.value.offsetHeight * ratio;
      canvas.value.getContext('2d').scale(ratio, ratio);

      // This library does not listen for canvas changes, so after the canvas is automatically
      // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
      // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
      // that the state of this library is consistent with visual state of the canvas, you
      // have to clear it manually.
      signaturePad.value.clear();
    };
    window.onresize = resizeCanvas;
    const createPad = () => {
      signaturePad.value = new SignaturePad(canvas.value);
      resizeCanvas();

      signaturePad.value.addEventListener('afterUpdateStroke', () => {
        triggered.value = true;
      }, { once: true });
    };

    const signModal = ref(null);

    const submit = () => {
      signModal.value.hide();
      emit('signed', signaturePad.value.toDataURL());
    };

    return {
      canvas,
      createPad,
      triggered,
      submit,
      signModal,
    };
  },
};
</script>

<style lang="sass" scoped>
.canvas
  width: 100%
  border: 2px dashed #e3e3e3
</style>
