import { useRouter } from '@/@core/utils/utils';
import store from '@/store';

export default () => {
  const { route, router } = useRouter();

  const updateGetParam = (name, value, cachePrefix) => {
    if (cachePrefix) {
      store.commit('cache/set', { name: `${cachePrefix}_${name}`, value });
    }

    const { query } = route.value;
    const model = {};
    model[name] = value;
    const newQuery = { ...query, ...model };
    router.replace({ query: newQuery }).catch(() => {});
  };

  const findGetParam = (name, cachePrefix) => {
    const { query } = route.value;

    if (cachePrefix) {
      const cached = store.state.cache[`${cachePrefix}_${name}`];
      if (cached !== undefined) {
        if (query[name] === undefined) updateGetParam(name, cached, cachePrefix);
        return cached;
      }
    }

    return query[name];
  };

  return {
    findGetParam,
    updateGetParam,
  };
};
